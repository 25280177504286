export const LINK_DATA = [
  {
    id: 'github',
    title: 'Github',
    url: 'https://github.com/BraydenNeale',
  },
  {
    id: 'linkedin',
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/in/brayden-neale-135254102',
  },
  {
    id: 'dynatrace-plugins-youtube',
    title: 'Webinar: Extending Dynatrace',
    url: 'https://www.youtube.com/watch?v=GafO-GtYD5g',
  },
  {
    id: 'dynatrace-snmp-github',
    title: 'Project: Dynatrace SNMP Monitoring',
    url: 'https://github.com/BraydenNeale/DT-SNMP',
  }
];
